import {
  SET_CARDS,
  SET_HISTORIC_PRICES_LOADING,
  SET_HISTORIC_PRICES,
  SET_SEARCH_LOADED,
  SET_PAGINATION,
  SET_AUTOCOMPLETE_ENTRIES,
  SET_CARD_ERROR,
  SET_CARD_LOADED,
  SET_ALL_PRINTINGS,
  SET_TCGPLAYER_ARTICLES,
  SET_TCGPLAYER_DECKS
} from './actionType'

import {
  fetchHistoricPrices,
  fetchCardsByCardName,
  fetchCardsAutocomplete,
  fetchAllPrintings,
  fetchTcgplayerArticles,
  fetchTcgplayerDecks
} from './requests'

export function setCards (cards) {
  return {
    type: SET_CARDS,
    cards
  }
}

export function setTcgplayerArticles (articles) {
  return {
    type: SET_TCGPLAYER_ARTICLES,
    articles
  }
}

export function setTcgplayerDecks (decks) {
  return {
    type: SET_TCGPLAYER_DECKS,
    decks
  }
}

export function setCardError (error) {
  return {
    type: SET_CARD_ERROR,
    error,
  }
}

export function setCardLoaded (loaded) {
  return {
    type: SET_CARD_LOADED,
    loaded,
  }
}

export function setHistoricPricesLoading (cardId) {
  return {
    type: SET_HISTORIC_PRICES_LOADING,
    cardId,
  }
}

export function setHistoricPrices (cardId, data, error) {
  return {
    type: SET_HISTORIC_PRICES,
    cardId,
    data,
    error,
  }
}

export function setSearchLoaded (loaded) {
  return {
    type: SET_SEARCH_LOADED,
    loaded
  }
}

export function setAutocompleteEntries (autocompleteEntries = []) {
  return {
    type: SET_AUTOCOMPLETE_ENTRIES,
    autocompleteEntries,
  }
}

export function setAllPrintings ({
  cardName, printings
}) {
  return {
    type: SET_ALL_PRINTINGS,
    cardName,
    printings,
  }
}

export function setPagination (pagination) {
  return {
    type: SET_PAGINATION,
    pagination: {
      numRows: pagination.numRows || 0,
      numPages: pagination.numPages || 0,
      previous: pagination.previous || null,
      next: pagination.next || null,
      loaded: pagination.loaded || false,
      orderBy: pagination.orderBy || 'card_name',
      order: pagination.order || 'ASC',
      page: pagination.page || 1,
      seed: pagination.seed || null,
      perPage: pagination.perPage || 25,
    }
  }
}

export function getCardsByCardName ({
  cardName, setName
}) {
  return function (dispatch, getState) {
    dispatch(setCardError(false))
    dispatch(setCardLoaded(false))
    let accessToken = getState().user.jwt
    return fetchCardsByCardName({
      cardName,
      setName,
      accessToken
    })
      .then(function (response) {
        dispatch(getAllPrintings(cardName))
        dispatch(setCardLoaded(true))
        return dispatch(setCards(response))
      })
      .catch(function (err) {
        dispatch(setCardError(true))
        dispatch(setCardLoaded(true))
        return err
        // return dispatch(setCardError(cardName, setName, foil))
      })
  }
}

const aggregateData = (data) => {
  const aggregatedData = {}

  if (data.length < 730) {
    data.forEach((entry) => {
      const date = new Date(entry.day)
      const dayKey = date.toISOString().split('T')[0]

      if (!aggregatedData[dayKey]) {
        aggregatedData[dayKey] = {
          day: date,
          buyBest: [],
          buyAverage: [],
          retailBest: [],
          retailAverage: [],
        }
      }

      const bestPrice = parseFloat(entry.best_price)
      const averagePrice = parseFloat(entry.average_price)

      if (entry.buy) {
        if (!isNaN(bestPrice)) aggregatedData[dayKey].buyBest.push(bestPrice)
        if (!isNaN(averagePrice)) aggregatedData[dayKey].buyAverage.push(averagePrice)
      } else {
        if (!isNaN(bestPrice)) aggregatedData[dayKey].retailBest.push(bestPrice)
        if (!isNaN(averagePrice)) aggregatedData[dayKey].retailAverage.push(averagePrice)
      }
    })
  } else {
    // Weekly summary
    const today = new Date()
    const thirtyDaysAgo = new Date(today.setDate(today.getDate() - 30))

    data.forEach((entry) => {
      const date = new Date(entry.day)

      if (date >= thirtyDaysAgo) {
        // Aggregate daily data for the last 30 days
        const dayKey = date.toISOString().split('T')[0]

        if (!aggregatedData[dayKey]) {
          aggregatedData[dayKey] = {
            day: date,
            buyBest: [],
            buyAverage: [],
            retailBest: [],
            retailAverage: [],
          }
        }

        const bestPrice = parseFloat(entry.best_price)
        const averagePrice = parseFloat(entry.average_price)

        if (entry.buy) {
          if (!isNaN(bestPrice)) aggregatedData[dayKey].buyBest.push(bestPrice)
          if (!isNaN(averagePrice)) aggregatedData[dayKey].buyAverage.push(averagePrice)
        } else {
          if (!isNaN(bestPrice)) aggregatedData[dayKey].retailBest.push(bestPrice)
          if (!isNaN(averagePrice)) aggregatedData[dayKey].retailAverage.push(averagePrice)
        }
      } else {
        // Aggregate weekly data for older entries
        const weekStart = new Date(date.setDate(date.getDate() - date.getDay()))
        const weekKey = weekStart.toISOString().split('T')[0]

        if (!aggregatedData[weekKey]) {
          aggregatedData[weekKey] = {
            day: weekStart,
            buyBest: [],
            buyAverage: [],
            retailBest: [],
            retailAverage: [],
          }
        }

        const bestPrice = parseFloat(entry.best_price)
        const averagePrice = parseFloat(entry.average_price)

        if (entry.buy) {
          if (!isNaN(bestPrice)) aggregatedData[weekKey].buyBest.push(bestPrice)
          if (!isNaN(averagePrice)) aggregatedData[weekKey].buyAverage.push(averagePrice)
        } else {
          if (!isNaN(bestPrice)) aggregatedData[weekKey].retailBest.push(bestPrice)
          if (!isNaN(averagePrice)) aggregatedData[weekKey].retailAverage.push(averagePrice)
        }
      }
    })
  }

  const formattedData = Object.values(aggregatedData).map(entry => {
    const avg = (arr) => (arr.length ? arr.reduce((a, b) => a + b, 0) / arr.length : null)
    return [
      entry.day,
      avg(entry.buyBest),
      avg(entry.buyAverage),
      avg(entry.retailBest),
      avg(entry.retailAverage),
    ]
  })

  return [
    ['Day', 'Best Buy', 'Average Buy', 'Best Retail', 'Average Retail'],
    ...formattedData,
  ]
}

export function getHistoricPrices (cardId) {
  return function (dispatch, getState) {
    let accessToken = getState().user.jwt
    fetchHistoricPrices(cardId, accessToken)
      .then(function (response) {
        const formattedData = aggregateData(response)
        return dispatch(setHistoricPrices(cardId, formattedData, false))
      })
      .catch(function (err) {
        // dispatch(setHistoricPrices(cardId, null, true))
        return err
      })
  }
}

export function getAllPrintings (cardName) {
  return function (dispatch, getState) {
    let accessToken = getState().user.jwt
    let allPrintings = getState().cards.allPrintings
    let cn = cardName.toLowerCase()
    if (allPrintings[cn] === undefined) {
      fetchAllPrintings({
        cardName: cn,
        accessToken
      })
        .then(function (response) {
          return dispatch(setAllPrintings({
            cardName: cn,
            printings: JSON.parse(response)
          }))
        })
        .catch(function (err) {
          // dispatch(setHistoricPrices(cardId, null, true))
          return err
        })

    }
  }
}

export function getCardsAutocomplete ({
  orderBy,
  order,
  page,
  perPage,
  numRows,
  numPages,
  previous,
  next,
  seed,
}, signal) {
  return function (dispatch, getState) {
    if (seed === '') {
      dispatch(setAutocompleteEntries([]))
      return dispatch(setPagination({
        perPage: 10,
        loaded: true
      }))
    }
    dispatch(setPagination({
      loaded: false,
      orderBy,
      order,
      page,
      perPage,
      numRows,
      numPages,
      previous,
      next,
      seed,
    }))
    let accessToken = getState().user.jwt
    return fetchCardsAutocomplete({
      seed,
      orderBy,
      order,
      page,
      perPage,
      accessToken
    }, signal)
      .then(function (response) {
        const {
          entries, cards, pagination
        } = response
        dispatch(setAutocompleteEntries(entries))
        dispatch(setCards(cards))
        return dispatch(setPagination({
          ...pagination,
          loaded: true,
        }))
      })
      .catch(function (err) {
        // TODO: Handle error
        return err
        // return dispatch(setCardError(cardName, setName, foil))
      })
  }
}


export function getTcgplayerArticles ({ cardName }) {
  return function (dispatch, getState) {
    let accessToken = getState().user.jwt
    return fetchTcgplayerArticles({
      cardName,
      accessToken
    })
      .then(function (response) {
        return dispatch(setTcgplayerArticles(response))
      })
      .catch(function (err) {
        console.log({
          err
        })
        return err
      })
  }
}

export function getTcgplayerDecks ({ cardName }) {
  return function (dispatch, getState) {
    let accessToken = getState().user.jwt
    return fetchTcgplayerDecks({
      cardName,
      accessToken
    })
      .then(function (response) {
        return dispatch(setTcgplayerDecks(response))
      })
      .catch(function (err) {
        console.log({
          err
        })
        return err
      })
  }
}